import { useRequestPaymentLinkMutation } from '@/generated-types/graphql.types';
import { store } from '@/main';
import { reactive, watch, ref } from '@vue/composition-api';
import { AuthPopups } from '@/Authentication/authentication.const';
export function withAuthMutationHook(queryHook) {
    return (options) => {
        let token;
        const { mutate, ...results } = queryHook(options);
        return {
            mutate: (variables, options) => {
                if (process.browser) {
                    token = store.state.authentication.accessToken;
                }
                return mutate({ ...variables, token }, options);
            },
            ...results
        };
    };
}
export function withAuthQueryHook(queryHook) {
    return (variables = {}, options) => {
        const token = store.state.authentication.accessToken;
        const _variables = reactive({ ...variables, token });
        watch(() => variables, () => {
            Object.entries(variables).map(([key, value]) => {
                _variables[key] = value;
            });
        }, { immediate: true });
        return queryHook(_variables, options);
    };
}
/**
 *
 *
 * @export
 * @param {number} bookingId
 * @param {string} redirectUrl Relative path where user will be redirected to.
 * @returns
 */
export function usePaymentLink(bookingId, redirectUrl) {
    const url = process.browser ? `${window.location.origin}/${redirectUrl}` : '';
    const { mutate, onDone, loading } = useRequestPaymentLinkMutation({});
    onDone(({ data }) => {
        const paymentLink = data?.create_payment_link?.payment_link;
        if (paymentLink)
            window.location.replace(paymentLink);
    });
    return {
        pay: () => mutate({
            data: { booking_id: bookingId, redirect_url: url }
        }),
        loading
    };
}
export const useResendTimer = root => {
    const timer = ref(0);
    let interval;
    const startTimer = () => {
        timer.value = 60;
        if (interval)
            clearInterval(interval);
        const countDown = () => {
            if (timer.value > 0) {
                timer.value -= 1;
            }
            else {
                clearInterval(interval);
            }
        };
        interval = setInterval(countDown, 1000);
    };
    watch(() => root.$route.query, ({ authPopup }) => {
        if (authPopup) {
            if ([
                AuthPopups.PHONE_LOGIN,
                AuthPopups.EMAIL_CONFIRMATION,
                AuthPopups.EMAIL_LOGIN,
                AuthPopups.PHONE_CONFIRMATION
            ].includes(authPopup)) {
                startTimer();
            }
        }
    }, { immediate: true });
    return {
        startTimer,
        timer
    };
};
