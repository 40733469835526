import i18n from '@/i18n/i18n-setup';
import { ServerErrors } from '@/util/graphql';
import Vue from 'vue';
import { reactive } from '@vue/composition-api';
export const server = (property) => {
    return function () {
        return this.serverValidate(property);
    };
};
export const serverValidationMixin = {
    data() {
        return {
            serverErrors: {}
        };
    },
    methods: {
        async serverValidate(key) {
            return !this.serverErrors[key];
        }
    }
};
export const validationRules = {
    alphabetAndDashOnlyValidator: i18n.t('common.errors.invalid_user_name'),
    IBAN: i18n.t('common.errors.iban_invalid'),
    required: i18n.t('common.errors.required'),
    checked: i18n.t('common.errors.required'),
    requiredIf: i18n.t('common.errors.required'),
    email: i18n.t('common.errors.email_invalid'),
    same: i18n.t('common.errors.same')
};
export const prefixError = (name, error) => {
    return {
        ...error,
        entity: error?.entity?.replace(new RegExp(`(^${name}$)`, 'g'), '_$1')
    };
};
const isGQLError = (error) => error && 'graphQLErrors' in error;
const getValidationError = (error) => {
    if (typeof error === 'object' && 'key' in error) {
        return i18n.t(`common.errors.${error.key}`).toString();
    }
    return error;
};
export const getErrors = (form, validation) => {
    const regExp = new RegExp(/^\$/);
    return Object.entries(form)
        .filter(([key]) => !regExp.test(key))
        .reduce((res, [key, field]) => ({
        ...res,
        [key]: Object.entries(validation).reduce((error, [rule, value]) => {
            if (key === 'phone') {
                if (field.formatted_number[rule] === false) {
                    return getValidationError(value);
                }
            }
            if ((key === rule && field === false) || field?.[rule] === false) {
                if (typeof value === 'object' && 'key' in value) {
                    return i18n.t(`common.errors.${value.key}`).toString();
                }
                return value;
            }
            return error;
        }, '')
    }), {});
};
const notValid = (errors) => {
    return Object.values(errors).some(item => typeof item === 'object' ? notValid(item) : Boolean(item));
};
export const isValid = (form, gqlError) => {
    const error = ServerErrors.extractGQLError(isGQLError(gqlError) ? gqlError.graphQLErrors : gqlError);
    const errors = Object.assign({}, getErrors(form, { ...error, ...validationRules }), form.$each?.$iter &&
        Object.values(form.$each.$iter).map((item) => getErrors(item, { ...validationRules, ...error })));
    return !notValid(errors);
};
export const validateForm = (form, gqlError, clientErrors, serverErrors) => {
    const error = ServerErrors.extractGQLError(isGQLError(gqlError) ? gqlError.graphQLErrors : gqlError);
    if (error && ServerErrors.isCommonError(error)) {
        return true;
    }
    const fieldRules = { ...validationRules };
    if (error) {
        if (ServerErrors.isValidationError(error)) {
            clientErrors && (clientErrors.general = error);
        }
        else {
            Object.assign(fieldRules, error);
            /** TODO: use Object entries */
            Vue.set(serverErrors, Object.keys(error)[0], Object.values(error)[0]);
        }
    }
    form.$touch();
    const errors = getErrors(form, fieldRules);
    if (form.$each?.$iter) {
        Object.assign(errors, Object.values(form.$each.$iter).map((item) => getErrors(item, fieldRules)));
    }
    if (clientErrors && !ServerErrors.isValidationError(error)) {
        clientErrors.fields = errors;
    }
    return !notValid(errors);
};
export const useValidation = (validationErrors = {}, serverErrors = {}) => {
    const clientErrors = reactive({
        fields: reactive(validationErrors || {}),
        general: undefined
    });
    let BEErrors = reactive(serverErrors ?? {});
    const reset = () => {
        clientErrors.fields = validationErrors || {};
        clientErrors.general = undefined;
        BEErrors = reactive(serverErrors || {});
    };
    const validate = (form, errors) => {
        reset();
        return validateForm(form, errors, clientErrors, BEErrors);
    };
    return {
        errors: clientErrors,
        serverErrors: BEErrors,
        validate,
        reset
    };
};
